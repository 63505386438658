

















































  import { defineComponent } from '@vue/composition-api'
  import TopBar from '@/components/organisms/o-top-bar.vue'
  import { RawLocation } from 'vue-router'
  import { userCanAccess } from '@/composables/UserGrant'

  declare interface TabItem {
  tab: string
  route: RawLocation | null
  disabled?: boolean
  listId: string
}

  export default defineComponent({
    name: 'Equipment',
    components: {
      TopBar,
    },
    data () {
      return {
        title: this.$t('equipment.title'),
        tab: 0,
        pages: [] as Array<TabItem>,
        add_equipment: [] as Array<any>,
      }
    },
    async created () {
      await this.init()
    },
    watch: {
      $route () {
        this.init()
      },
    },
    computed: {
      addEquipmentGrant () {
        const btnAddVehicule = {
          text: this.$t('equipment.add.vehicle'),
          value: 'Vehicle Create',
        }

        const btnAddTeachingTool = {
          text: this.$t('equipment.add.teaching_tool'),
          value: 'TeachingTool Create',
        }

        if (userCanAccess('TeachingTool Create')) {
          this.add_equipment.push(btnAddVehicule)
        }
        if (userCanAccess('Vehicle Create')) {
          this.add_equipment.push(btnAddTeachingTool)
        }

        return this.add_equipment
      },
    },
    methods: {
      async init () {
        this.pages = []
        const vehiculePage = {
          tab: this.$t('equipment.form.tab.vehicle') as string,
          route: { name: 'Vehicle List' },
          listId: 'vehicleList',
        }

        const teachingToolPage = {
          tab: this.$t('equipment.form.tab.teaching_tool') as string,
          route: { name: 'TeachingTool List' },
          listId: 'teachingToolList',
        }

        if (userCanAccess('TeachingTool List')) {
          this.pages.push(teachingToolPage)
        }
        if (userCanAccess('Vehicle List')) {
          this.pages.push(vehiculePage)
        }
      },
      cancel () {
        this.$router.push({ name: 'Equipment List' })
      },
    },
  })
